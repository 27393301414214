<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header />

    <Footer logged />

    <div class="page-title page-title-fixed">
      <div>
        <h1 class="font-16 color-highlight mb-n3"></h1>
      </div>
    </div>
    <div class="page-title-clear"></div>

    <div class="page-content">
      <div>
        <div class="card card-style">
          <div class="content">
            <p class="mb-n1 color-highlight font-600 font-12"></p>
            <h4>Tambahkan Pengalaman Kerja</h4>
            <p class="color-red-dark"></p>

            <div class="mt-5 mb-3">
              <!-- Form -->
              <div
                class="input-style has-borders no-icon validate-field mb-4"
                :class="{ 'input-style-active': name }"
              >
                <input
                  type="name"
                  class="form-control validate-name"
                  id="form1"
                  placeholder="Nama Pekerjaan"
                  v-model="name"
                  required
                />
                <label for="form1">Nama Pekerjaan</label>
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
              </div>

              <div
                class="input-style has-borders no-icon mb-4"
                :class="{ 'input-style-active': desc }"
              >
                <textarea
                  id="form1c"
                  v-model="desc"
                  placeholder="Apa yang anda lakukan pada pekerjaan ini"
                  required
                ></textarea>
                <label for="form1c">Deskripsi Pekerjaan</label>
              </div>
            </div>
            <a
              @click.prevent="submitForm"
              href="#"
              class="
                btn btn-full btn-m
                gradient-highlight
                rounded-s
                font-13 font-600
                mt-4
              "
              >Submit</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Page content ends here-->

    <!-- Main Menu-->
    <div
      id="menu-main"
      class="menu menu-box-left rounded-0"
      data-menu-width="280"
      data-menu-active="nav-welcome"
    >
      <MenuMain @close="menuClose" />
    </div>

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <!-- Colors Menu-->
    <div
      id="menu-colors"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-colors.html"
      data-menu-height="480"
    >
      <MenuColors @close="menuClose" />
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MenuMain from "@/components/menu/MenuMain";
import MenuColors from "@/components/menu/MenuColors";
export default {
  name: "AddExperience",
  components: {
    Header,
    Footer,
    MenuMain,
    MenuColors,
  },
  data() {
    return {
      id: "",
      name: "",
      desc: "",
      apiUrl: "/api/v1/job-seeker/job/experience/",
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    this.isLoading = false;
    this.id = this.$route.params.id;
    if (typeof this.id !== "undefined") {
      this.getData();
    }
  },
  methods: {
    menuClose,
    menuOpen,
    getData() {
      axios
        .get(this.apiUrl + this.id + "/")
        .then((response) => {
          this.name = response.data.name;
          this.desc = response.data.desc;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log();
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
    async submitForm() {
      var formData = {
        name: this.name,
        desc: this.desc,
      };
      var url = "";
      var request_method = "";
      if (typeof this.id !== "undefined") {
        url = this.apiUrl + this.id + "/";
        request_method = axios.patch(url, formData);
      } else if (typeof this.id === "undefined") {
        url = this.apiUrl + "?field=experience";
        request_method = axios.post(url, formData);
      }

      await request_method
        .then((response) => {
          this.$router.push({ name: "JobExperience" });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
